'use client';

import * as Sentry from '@sentry/nextjs';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import { useEffect } from 'react';

import Button from '@/components/Button';

import styles from './styles.module.scss';

const Error = ({ error, reset }: { error: Error; reset: () => void }) => {
  const params = useParams();
  const lng = params.lng ?? 'en';

  const headingText =
    lng === 'pl' ? 'Coś poszło nie tak!' : 'Something went wrong!';
  const buttonText = lng === 'pl' ? 'Spróbuj ponownie' : 'Try again';
  const goBackText =
    lng === 'pl' ? 'Wróć na stronę główną' : 'Back to homepage';

  useEffect(() => {
    Sentry.captureException(error);
    console.error(error);
  }, [error]);

  return (
    <section>
      <div className={styles.container}>
        <h2> {headingText}</h2>
        <Button onClick={() => reset()}>{buttonText}</Button>
        <Link href="/" className={styles.link}>
          {goBackText}
        </Link>
      </div>
    </section>
  );
};

export default Error;
